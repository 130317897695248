import { Container, Typography, Box, TextField } from '@mui/material'
import { change_password } from 'api/clothes_service'
import ButtonWithLoading from 'components/button_with_loading'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDocumentTitle } from 'hooks'

const ChangePassword = () => {
  useDocumentTitle('Change Password')
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [isPasswordsMatching, setIsPasswordsMatching] = useState(true)
  const [isPasswordTooShort, setIsPasswordTooShort] = useState(false)

  enum FieldName {
    CURRENT_PASSWORD = 'current_password',
    NEW_PASSWORD = 'new_password',
    CONFIRM_NEW_PASSWORD = 'confirm_new_password',
  }

  const handleChange = async (event: React.FormEvent<HTMLFormElement>) => {
    const data = new FormData(event.currentTarget)
    setIsPasswordsMatching(
      data.get(FieldName.NEW_PASSWORD) ===
        data.get(FieldName.CONFIRM_NEW_PASSWORD)
    )

    const password = data.get(FieldName.NEW_PASSWORD) as string
    setIsPasswordTooShort(password.length < 12 && password.length !== 0)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true)
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    await change_password(
      data.get(FieldName.CURRENT_PASSWORD) as string,
      data.get(FieldName.NEW_PASSWORD) as string,
      data.get(FieldName.CONFIRM_NEW_PASSWORD) as string
    )
    navigate('/')
    setIsLoading(false)
  }

  return (
    <Container maxWidth="xs">
      <Typography variant="h4">Change Password</Typography>
      <Box component="form" onSubmit={handleSubmit} onChange={handleChange}>
        <TextField
          margin="normal"
          required
          fullWidth
          name={FieldName.CURRENT_PASSWORD}
          label="Current password"
          type="password"
          id="current-password"
          autoComplete="current-password"
        />
        {/* TODO: Display visibility button */}
        <TextField
          margin="normal"
          required
          fullWidth
          name={FieldName.NEW_PASSWORD}
          label="New password"
          type="password"
          id="new-password"
          autoComplete="new-password"
          error={isPasswordTooShort}
          helperText={
            isPasswordTooShort ? 'Please choose a longer password.' : undefined
          }
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name={FieldName.CONFIRM_NEW_PASSWORD}
          label="Confirm new password"
          type="password"
          id="new-password-repeat"
          autoComplete="new-password"
          error={!isPasswordsMatching}
          helperText={
            isPasswordsMatching ? undefined : "Passwords don't match."
          }
        />
        <ButtonWithLoading
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          isLoading={isLoading}
          disabled={!isPasswordsMatching}
        >
          Submit
        </ButtonWithLoading>
      </Box>
    </Container>
  )
}

export default ChangePassword
