import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  Stack,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ClothingItemWithPriceAndSku } from 'models'

interface ClothingItemProps {
  clothingItem: ClothingItemWithPriceAndSku
  index: number
  buttons?: React.ReactNode
}

const ClothingItemAccordion = ({
  clothingItem,
  index,
  buttons,
}: ClothingItemProps) => {
  return (
    <Accordion defaultExpanded elevation={3}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`clothing-item-${index + 1}-content`}
        id={`clothing-item-${index + 1}-header`}
      >
        <Typography variant="h6">
          Item #{index + 1}: {clothingItem.brand} | {clothingItem.type} (
          {clothingItem.sku})
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs>
            <Typography variant="body1">
              Category: {clothingItem.category}
            </Typography>
            <Typography variant="body1">Type: {clothingItem.type}</Typography>
            <Typography variant="body1">
              Condition: {clothingItem.condition}
            </Typography>
            <Typography variant="body1">Brand: {clothingItem.brand}</Typography>
            <Typography variant="body1">SKU: {clothingItem.sku}</Typography>
            <Typography variant="body1">
              Crediting amount: ${clothingItem.crediting_amount}
            </Typography>
            <Typography variant="body1">
              Sale price: ${clothingItem.sale_price}
            </Typography>
            <Typography variant="body1">
              Uploaded by: {clothingItem.staff}
            </Typography>
          </Grid>
          {buttons && (
            <Grid item xs="auto" display="flex" alignItems="end">
              <Stack spacing={1} direction="row">
                {buttons}
              </Stack>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default ClothingItemAccordion
