import React from 'react'
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Grid,
  useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

type BasicDialogProps = {
  open: boolean
  title: string
  onClose: () => void
  actions?: React.ReactNode
  children: React.ReactNode
}

const BasicDialog = ({
  open,
  title,
  onClose,
  actions,
  children,
}: BasicDialogProps) => {
  const theme = useTheme()
  return (
    <Dialog open={open}>
      <DialogTitle sx={{ pr: 2 }}>
        <Grid container spacing={5}>
          <Grid item xs display="flex" alignItems="center">
            <Typography variant="h6">{title}</Typography>
          </Grid>
          <Grid item xs="auto">
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent
        sx={{
          pt: `${theme.spacing(1)} !important`,
        }}
      >
        {children}
      </DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  )
}

export default BasicDialog
