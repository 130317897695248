const env = process.env.REACT_APP_ENV ?? 'LOCAL'

const ENV = {
  name: env,
  IS_PROD: env === 'PROD',
  IS_STAGING: env === 'STAGING',
  IS_LOCAL: env === 'LOCAL',
}

export default ENV
