import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  Stack,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ClothingRejectedItem } from 'models'

interface ClothingRejectedItemProps {
  rejectedItem: ClothingRejectedItem
  index: number
  buttons?: React.ReactNode
}

const ClothingRejectedItemAccordion = ({
  rejectedItem,
  index,
  buttons,
}: ClothingRejectedItemProps) => {
  return (
    <Accordion defaultExpanded elevation={3}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`rejected-item-${index + 1}-content`}
        id={`rejected-item-${index + 1}-header`}
      >
        <Typography variant="h6">
          Item #{index + 1}: {rejectedItem.brand} | {rejectedItem.type}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs>
            <Typography variant="body1">
              Category: {rejectedItem.category}
            </Typography>
            <Typography variant="body1">Type: {rejectedItem.type}</Typography>
            <Typography variant="body1">Brand: {rejectedItem.brand}</Typography>
            <Typography variant="body1">
              Uploaded by: {rejectedItem.staff}
            </Typography>
          </Grid>
          {buttons && (
            <Grid item xs="auto" display="flex" alignItems="end">
              <Stack spacing={1} direction="row">
                {buttons}
              </Stack>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default ClothingRejectedItemAccordion
