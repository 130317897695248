import { Box, Stack, CircularProgress, Typography } from '@mui/material'
import { logout_user } from 'api/clothes_service'
import { UserContext } from 'base'
import { useContext, useEffect } from 'react'

const Logout = () => {
  const user = useContext(UserContext)

  useEffect(() => {
    const logout = async () => {
      await logout_user()
      user.setLoggedOut()
    }
    logout()
  }, [user])

  return (
    <Box display="flex" justifyContent="center" pt={2}>
      <Stack display="flex" alignItems="center" spacing={1}>
        <CircularProgress />
        <Typography variant="body1">Logging out...</Typography>
      </Stack>
    </Box>
  )
}

export default Logout
