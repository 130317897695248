import { useCallback, useContext, useEffect, useState } from 'react'
import { Alert, Box, CircularProgress, Stack, Typography } from '@mui/material'

import { ClothingDataDB, UploadStatus } from 'models'
import UploadCard from 'components/upload_card'
import {
  get_crediting_uploads_completed,
  get_crediting_uploads_for_review,
  get_crediting_uploads_in_progress,
  get_crediting_uploads_partial,
} from 'api/clothes_service'
import { useDocumentTitle } from 'hooks'
import { UnauthorisedError } from 'errors'
import { UserContext } from 'base'

interface UploadsProps {
  uploadStatus: UploadStatus
}

const UploadsContent = ({ uploadStatus }: UploadsProps) => {
  const user = useContext(UserContext)

  const [clothingUploads, setClothingUploads] =
    useState<Array<ClothingDataDB>>()
  const [error, setError] = useState<string>()

  const getClothingUploads = async (uploadStatus: UploadStatus) => {
    switch (uploadStatus) {
      case UploadStatus.IN_PROGRESS:
        return await get_crediting_uploads_in_progress()
      case UploadStatus.PARTIAL:
        return await get_crediting_uploads_partial()
      case UploadStatus.IN_REVIEW:
        return await get_crediting_uploads_for_review()
      case UploadStatus.COMPLETED:
        return await get_crediting_uploads_completed()
    }
  }

  const getUploadRoute = (uploadStatus: UploadStatus) => {
    switch (uploadStatus) {
      case UploadStatus.IN_PROGRESS:
        return '/in-progress'
      case UploadStatus.PARTIAL:
        return '/partial'
      case UploadStatus.IN_REVIEW:
        return `/uploads`
      case UploadStatus.COMPLETED:
        return `/completed`
    }
  }

  const populateClothingUploads = useCallback(async () => {
    setError(undefined)
    try {
      const clothingUploads = await getClothingUploads(uploadStatus)
      setClothingUploads(clothingUploads)
    } catch (e: any) {
      if (e instanceof UnauthorisedError) {
        return user.setLoggedOut()
      }

      setError(e.message)
    }
  }, [uploadStatus, user])

  useEffect(() => {
    populateClothingUploads()
  }, [populateClothingUploads])

  if (error !== undefined) {
    return (
      <Alert severity="error">
        <Typography variant="h5">{error}</Typography>
      </Alert>
    )
  }

  if (clothingUploads === undefined) {
    return (
      <Box display="flex" justifyContent="center" pt={2}>
        <Stack display="flex" alignItems="center" spacing={1}>
          <CircularProgress />
          <Typography variant="body1">Loading data...</Typography>
        </Stack>
      </Box>
    )
  }

  if (clothingUploads.length === 0) {
    return <Typography>No uploads.</Typography>
  }

  return clothingUploads.map((clothingData) => (
    <UploadCard
      key={clothingData.id}
      clothingData={clothingData}
      link={`${getUploadRoute(uploadStatus)}/${clothingData.id}`}
    ></UploadCard>
  ))
}

const Uploads = ({ uploadStatus }: UploadsProps) => {
  const getHeader = () => {
    switch (uploadStatus) {
      case UploadStatus.IN_PROGRESS:
        return 'In-Progress Uploads'
      case UploadStatus.PARTIAL:
        return 'Partial Uploads'
      case UploadStatus.IN_REVIEW:
        return 'Uploads For Review'
      case UploadStatus.COMPLETED:
        return 'Completed Uploads'
    }
  }

  useDocumentTitle(getHeader())

  return (
    <Stack spacing={2} key={uploadStatus}>
      <Typography variant="h3">{getHeader()}</Typography>
      <UploadsContent uploadStatus={uploadStatus} />
    </Stack>
  )
}

export default Uploads
