import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded'
import { Fab, Zoom, useScrollTrigger } from '@mui/material'

// References:
// https://github.com/mui/material-ui/pull/30441
// https://mui.com/material-ui/react-app-bar/#back-to-top
const BackToTop = () => {
  const trigger = useScrollTrigger({
    target: window,
    disableHysteresis: true,
    threshold: 500,
  })

  return (
    <Zoom in={trigger}>
      <Fab
        color="primary"
        size="small"
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        sx={{
          position: 'fixed',
          bottom: 24,
          right: 24,
          zIndex: 200000,
        }}
      >
        <KeyboardArrowUpRoundedIcon />
      </Fab>
    </Zoom>
  )
}

export default BackToTop
