import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material'
import SortIcon from '@mui/icons-material/Sort'

import { ClothingDataDB } from 'models'
import UploadCard from 'components/upload_card'
import { get_crediting_uploads_completed_paginated } from 'api/clothes_service'
import { useDocumentTitle } from 'hooks'
import { UnauthorisedError } from 'errors'
import { useSearchParams } from 'react-router-dom'
import BackToTop from 'components/back_to_top'
import { UserContext } from 'base'

const UploadsContent = () => {
  const [searchParams] = useSearchParams()

  const [clothingUploads, setClothingUploads] =
    useState<Array<ClothingDataDB>>()
  const [next, setNext] = useState<string | null>(null)
  const [nextId, setNextId] = useState<string | null>(null)
  const [error, setError] = useState<string>()
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false)
  const observerTarget = useRef<HTMLElement>(null)
  const user = useContext(UserContext)

  const populateClothingUploads = useCallback(async () => {
    setError(undefined)
    setClothingUploads(undefined)
    try {
      const clothingUploadsData =
        await get_crediting_uploads_completed_paginated(
          searchParams.get('sort'),
          null,
          null,
          null,
          null
        )
      setClothingUploads(clothingUploadsData.uploads)
      setNext(clothingUploadsData.next)
      setNextId(clothingUploadsData.next_id)
    } catch (e: any) {
      if (e instanceof UnauthorisedError) {
        return user.setLoggedOut()
      }

      setError(e.message)
    }
  }, [searchParams, user])

  const getMoreUploads = useCallback(async () => {
    if (isLoadingMore) return
    setIsLoadingMore(true)
    // TODO: Refactor?
    // TODO: Error handling??
    try {
      const clothingUploadsData =
        await get_crediting_uploads_completed_paginated(
          searchParams.get('sort'),
          null,
          null,
          next,
          nextId
        )
      setNext(clothingUploadsData.next)
      setNextId(clothingUploadsData.next_id)
      setClothingUploads((existingUploads) =>
        existingUploads !== undefined
          ? [...existingUploads, ...clothingUploadsData.uploads]
          : clothingUploadsData.uploads
      )
    } catch (e: any) {
      return
    }
    setIsLoadingMore(false)
  }, [next, nextId, searchParams, isLoadingMore])

  useEffect(() => {
    let observerRefValue: HTMLElement | null = null

    // Reference: https://blog.logrocket.com/3-ways-implement-infinite-scroll-react/
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          getMoreUploads()
        }
      },
      { threshold: 1 }
    )

    if (observerTarget.current) {
      observer.observe(observerTarget.current)
      observerRefValue = observerTarget.current
    }

    return () => {
      if (observerRefValue !== null) {
        observer.unobserve(observerRefValue)
      }
    }
  }, [observerTarget, getMoreUploads])

  useEffect(() => {
    populateClothingUploads()
  }, [populateClothingUploads])

  if (error !== undefined) {
    return (
      <Alert severity="error">
        <Typography variant="h5">{error}</Typography>
      </Alert>
    )
  }

  if (clothingUploads === undefined) {
    return (
      <Box display="flex" justifyContent="center" pt={2}>
        <Stack display="flex" alignItems="center" spacing={1}>
          <CircularProgress />
          <Typography variant="body1">Loading data...</Typography>
        </Stack>
      </Box>
    )
  }

  if (clothingUploads.length === 0) {
    return <Typography>No uploads.</Typography>
  }

  return (
    <>
      {clothingUploads.map((clothingData) => (
        <UploadCard
          key={clothingData.id}
          clothingData={clothingData}
          link={`/completed/${clothingData.id}`}
        ></UploadCard>
      ))}
      {isLoadingMore ? (
        <Stack display="flex" alignItems="center" spacing={1}>
          <CircularProgress />
        </Stack>
      ) : (
        (next !== null || nextId !== null) && <Box ref={observerTarget}></Box>
      )}
    </>
  )
}

enum SortMethod {
  APPROVAL_DATE = 'Approval date (newest first)',
  UPLOADED_DATE = 'Uploaded date (newest first)',
  COLLECTION_DATE = 'Collection date (newest first)',
}

const SortButton = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const sortButtonRef = useRef<HTMLButtonElement>(null)
  const [selectedMethod, setSelectedMethod] = useState(SortMethod.APPROVAL_DATE)
  const [, setSearchParams] = useSearchParams()

  const handleSortMethodClick = (sortMethod: SortMethod) => {
    setSelectedMethod(sortMethod)
    setIsMenuOpen(false)
    setSearchParams({
      sort: sortMethod,
    })
  }

  return (
    <Stack direction="row">
      <Button
        id="sort-button"
        startIcon={<SortIcon />}
        onClick={() => {
          setIsMenuOpen(true)
        }}
        ref={sortButtonRef}
      >
        Sort by
      </Button>
      <Menu
        open={isMenuOpen}
        onClose={() => setIsMenuOpen(false)}
        anchorEl={sortButtonRef.current}
        MenuListProps={{
          'aria-labelledby': 'sort-button',
        }}
      >
        {Object.values(SortMethod).map((sortMethod) => (
          <MenuItem
            key={sortMethod}
            onClick={() => handleSortMethodClick(sortMethod)}
            selected={selectedMethod === sortMethod}
          >
            {sortMethod}
          </MenuItem>
        ))}
      </Menu>
      <BackToTop />
    </Stack>
  )
}

const CompletedUploads = () => {
  useDocumentTitle('Completed Uploads')

  return (
    <Stack spacing={2}>
      <Typography variant="h3">{'Completed Uploads'}</Typography>
      <SortButton />
      <UploadsContent />
    </Stack>
  )
}

export default CompletedUploads
