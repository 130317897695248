import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Base from 'base'
import ENV from 'environment'

const WebApp = () => (
  <>
    {!ENV.IS_PROD && (
      <>
        {/* Note: There are issues with Bitwarden header. */}
        <AppBar position="sticky" sx={{ backgroundColor: 'black' }}>
          <Toolbar variant="dense">
            <Typography textAlign="center" sx={{ flexGrow: 1 }}>
              {ENV.IS_STAGING ? 'STAGING ENVIRONMENT' : 'LOCAL DEV ENVIRONMENT'}
            </Typography>
          </Toolbar>
        </AppBar>
      </>
    )}
    <Base />
  </>
)

export default WebApp
