import { useSearchParams } from 'react-router-dom'

const TelegramQrScannerMiniApp = () => {
  const telegram = window.Telegram.WebApp
  const [searchParams] = useSearchParams()
  const searchParamType = searchParams.get('type')
  const codeType = searchParamType === '' ? null : searchParamType

  if (telegram.platform === 'unknown') {
    return (
      <>
        Sorry! Your environment seems to be unsupported. Please make sure this
        page was loaded from Telegram.
      </>
    )
  }

  if (telegram.platform === 'tdesktop') {
    telegram.showAlert(
      `QR code scanning is not available on desktop. ` +
        `Please either type out the ${codeType ?? 'code'}, ` +
        `or switch to the mobile app to scan.`
    )
    telegram.close()
    return <></>
  }

  const onConfirmButtonPressed = (isOk: boolean, qrText: string) => {
    if (!isOk) return
    telegram.closeScanQrPopup()
    telegram.sendData(qrText)
  }

  const onQrTextReceived = (text: string) => {
    telegram.showConfirm(`Use this ${codeType ?? 'code'}?\n\n${text}`, (isOk) =>
      onConfirmButtonPressed(isOk, text)
    )
  }

  telegram.showScanQrPopup(
    codeType === null ? {} : { text: `Please scan the ${codeType}` },
    onQrTextReceived
  )
  telegram.onEvent('scanQrPopupClosed', () => telegram.close())

  return <></>
}
export default TelegramQrScannerMiniApp
