import React from 'react'
import { Box, Button, CircularProgress, SxProps, Theme } from '@mui/material'

interface ButtonWithLoadingProps {
  variant: 'text' | 'outlined' | 'contained'
  type?: 'submit' | 'reset' | 'button'
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
  fullWidth?: boolean
  onClick?: () => Promise<void>
  children: React.ReactNode
  isLoading: boolean
  disabled?: boolean
  sx?: SxProps<Theme>
}

const ButtonWithLoading = ({
  variant,
  type,
  color,
  fullWidth,
  onClick,
  children,
  isLoading,
  disabled,
  sx,
}: ButtonWithLoadingProps) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Button
        variant={variant}
        type={type}
        fullWidth={fullWidth}
        onClick={onClick}
        color={color}
        disabled={isLoading || disabled}
        sx={{ height: '100%', ...sx }}
      >
        {children}
      </Button>

      {isLoading && (
        <CircularProgress
          size={24}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Box>
  )
}

export default ButtonWithLoading
