import { useLayoutEffect } from 'react'
import Cookies from 'js-cookie'
import ENV from 'environment'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { User } from 'base'

const baseTitle = 'Thryft OMS 2.0'

export const useDocumentTitle = (title?: string) => {
  useLayoutEffect(() => {
    document.title = title !== undefined ? `${title} - ${baseTitle}` : baseTitle
    if (!ENV.IS_PROD) document.title = `[${ENV.name}] ${document.title}`
  }, [title])
}

/**
 * @deprecated Replaced with {@link User.isLoggedIn}
 * Trivial check for whether user is authenticated. Does not account for the possibility of user's token being expired.
 *
 * @returns Whether user is authenticated
 */
export const useIsAuthenticated = () => {
  return Cookies.get('csrf_access_token') !== undefined
}
