import {
  TextField,
  Button,
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  MenuItem,
  InputAdornment,
  Typography,
  Grid,
} from '@mui/material'
import { get_clothing_item_pricing } from 'api/clothes_service'
import { AlreadyExistsError, UnauthorisedError } from 'errors'
import {
  ClothingCategory,
  Condition,
  MenClothing,
  WomenClothing,
  ClothingCategoryUtil,
  ClothingItemWithPriceAndSku,
  constructSku,
} from 'models'
import React, { useContext, useState } from 'react'
import ButtonWithLoading from './button_with_loading'
import { UserContext } from 'base'

interface ClothingItemFormProps {
  clothingItem: ClothingItemWithPriceAndSku
  onSubmit: (clothingItem: ClothingItemWithPriceAndSku) => Promise<void>
}

const ClothingItemForm = ({
  clothingItem,
  onSubmit,
}: ClothingItemFormProps) => {
  enum Page {
    ITEM = 1,
    SKU = 2,
  }

  const [clothingCategory, setClothingCategory] = useState<ClothingCategory>(
    clothingItem.category
  )
  const [clothingType, setClothingType] = useState<
    MenClothing | WomenClothing | ''
  >(clothingItem.type)
  const [condition, setCondition] = useState<Condition | ''>(
    clothingItem.condition
  )
  const [brand, setBrand] = useState<string>(clothingItem.brand)
  const [creditingAmount, setCreditingAmount] = useState<number>(
    clothingItem.crediting_amount
  )
  const [salePrice, setSalePrice] = useState<number>(clothingItem.sale_price)
  const [skuNum, setSkuNum] = useState<string>(
    clothingItem.sku.split('.').pop() as string
  )
  const [hasSkuError, setHasSkuError] = useState<boolean>(false)
  const [page, setPage] = useState<Page>(Page.ITEM)
  const [isNextPageLoading, setIsNextPageLoading] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const user = useContext(UserContext)

  const handleClothingCategory = (
    event: React.MouseEvent<HTMLElement>,
    newClothingCategory: ClothingCategory | null
  ) => {
    if (newClothingCategory !== null) {
      setClothingCategory(newClothingCategory)
      if (
        !Object.values(
          ClothingCategoryUtil.clothingTypes(newClothingCategory)
        ).includes(clothingType)
      ) {
        setClothingType('')
      }
    }
  }

  const handleClothingType = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    switch (clothingCategory) {
      case ClothingCategory.WOMEN:
        setClothingType(event.target.value as WomenClothing)
        break
      case ClothingCategory.MEN:
        setClothingType(event.target.value as MenClothing)
        break
    }
  }

  const handleCondition = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setCondition(event.target.value as Condition)
  }

  const handleBrand = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setBrand(event.target.value)
  }

  const handleSku = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSkuNum(event.target.value)
  }

  const skuPrefix = constructSku('')

  // If else page
  switch (page) {
    case Page.ITEM:
      return (
        <Box
          component="form"
          onSubmit={async (event) => {
            setIsNextPageLoading(true)
            event.preventDefault()
            let clothingItemWithPrice
            try {
              clothingItemWithPrice = await get_clothing_item_pricing({
                ...clothingItem,
                category: clothingCategory,
                type: clothingType as MenClothing | WomenClothing,
                brand,
                condition: condition as Condition,
              })
            } catch (e: any) {
              if (e instanceof UnauthorisedError) {
                user.setLoggedOut()
              }
              setIsNextPageLoading(false)
              return
            }
            setCreditingAmount(clothingItemWithPrice.crediting_amount)
            setSalePrice(clothingItemWithPrice.sale_price)
            setPage(Page.SKU)
            setIsNextPageLoading(false)
          }}
        >
          <Stack spacing={3}>
            <ToggleButtonGroup
              exclusive
              value={clothingCategory}
              onChange={handleClothingCategory}
            >
              <ToggleButton value={ClothingCategory.WOMEN}>
                {ClothingCategory.WOMEN}
              </ToggleButton>
              <ToggleButton value={ClothingCategory.MEN}>
                {ClothingCategory.MEN}
              </ToggleButton>
            </ToggleButtonGroup>

            <TextField
              required
              select
              fullWidth
              value={clothingType}
              label="Clothing Type"
              onChange={handleClothingType}
            >
              {Object.values(
                ClothingCategoryUtil.clothingTypes(clothingCategory)
              ).map((clothingType) => (
                <MenuItem key={clothingType} value={clothingType}>
                  {clothingType}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              required
              select
              fullWidth
              value={condition}
              label="Condition"
              onChange={handleCondition}
            >
              {Object.values(Condition).map((condition) => (
                <MenuItem key={condition} value={condition}>
                  {condition}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              required
              label="Brand"
              fullWidth
              onChange={handleBrand}
              value={brand}
            />
            <Stack direction="row" justifyContent="right">
              <ButtonWithLoading
                type="submit"
                variant="contained"
                isLoading={isNextPageLoading}
              >
                Next
              </ButtonWithLoading>
            </Stack>
          </Stack>
        </Box>
      )
    case Page.SKU:
      return (
        <Box
          component="form"
          onSubmit={async (event) => {
            setIsSaving(true)
            event.preventDefault()
            setHasSkuError(false)
            try {
              await onSubmit({
                ...clothingItem,
                category: clothingCategory,
                type: clothingType as MenClothing | WomenClothing,
                brand,
                condition: condition as Condition,
                crediting_amount: creditingAmount,
                sale_price: salePrice,
                sku: constructSku(skuNum),
              })
            } catch (e) {
              if (e instanceof AlreadyExistsError) {
                setHasSkuError(true)
              }
            }
            setIsSaving(false)
          }}
        >
          <Stack spacing={3}>
            <Stack spacing={1}>
              <Stack>
                <Typography variant="body1">
                  Crediting Amount: ${creditingAmount}
                </Typography>
                <Typography variant="body1">
                  Sale Price: ${salePrice}
                </Typography>
              </Stack>

              {clothingItem.sale_price !== salePrice ? (
                <Typography variant="body1">
                  Please grab a new label with a price of <b>${salePrice}</b>{' '}
                  and replace the label on the clothing item. Enter the new SKU
                  below:
                </Typography>
              ) : (
                <Typography variant="body1">
                  As the sale price has not changed, the SKU label can be
                  reused.
                </Typography>
              )}
            </Stack>

            <TextField
              required
              type="number"
              inputProps={{ min: 1 }}
              label="SKU"
              fullWidth
              value={skuNum}
              onChange={handleSku}
              error={hasSkuError}
              helperText={
                hasSkuError
                  ? 'Already taken, please choose a different SKU.'
                  : undefined
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ mr: 0 }}>
                    {skuPrefix}
                  </InputAdornment>
                ),
              }}
            />
            <Grid container>
              <Grid item xs>
                <Button
                  variant="contained"
                  color="info"
                  onClick={() => {
                    setPage(Page.ITEM)
                  }}
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs display="flex" justifyContent="right">
                <ButtonWithLoading
                  type="submit"
                  variant="contained"
                  isLoading={isSaving}
                >
                  Save
                </ButtonWithLoading>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      )
  }
}

export default ClothingItemForm
