import React, { useState } from 'react'
import ButtonWithLoading from './button_with_loading'

interface ButtonWithAutoLoadingProps {
  variant: 'text' | 'outlined' | 'contained'
  type?: 'submit' | 'reset' | 'button' | undefined
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
  fullWidth?: boolean
  onClick: () => Promise<void>
  children: React.ReactNode
}

const ButtonWithAutoLoading = ({
  variant,
  type,
  color,
  fullWidth,
  onClick,
  children,
}: ButtonWithAutoLoadingProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  return (
    <ButtonWithLoading
      variant={variant}
      type={type}
      color={color}
      fullWidth={fullWidth}
      onClick={async () => {
        setIsLoading(true)
        await onClick()
        setIsLoading(false)
      }}
      isLoading={isLoading}
    >
      {children}
    </ButtonWithLoading>
  )
}

export default ButtonWithAutoLoading
