export class NotFoundError extends Error {}

export class AlreadyExistsError extends Error {}

export class UnauthorisedError extends Error {}

export class APIError extends Error {
  constructor(message?: string) {
    if (message === undefined) {
      super(`API Error`)
      return
    }
    super(`API Error: ${message}`)
  }
}
