import { Box, MenuItem, Stack, TextField, Typography } from '@mui/material'
import ButtonWithLoading from 'components/button_with_loading'
import { useDocumentTitle } from 'hooks'
import { useContext, useState } from 'react'
import { ExportToCsv } from 'export-to-csv-fix-source-map'
import { constructCdsSku, constructRecordsSku } from 'models'
import {
  get_and_increment_next_records_sku,
  get_and_increment_next_cds_sku,
} from 'api/clothes_service'
import { UnauthorisedError } from 'errors'
import { UserContext } from 'base'

enum ProductType {
  VINYL = 'Vinyl Records',
  CD = 'CDs',
}

const SkuLabels = () => {
  useDocumentTitle('Download SKU Labels')
  const user = useContext(UserContext)
  const [qty, setQty] = useState('0')
  const [productType, setProductType] = useState(ProductType.VINYL)

  const [isGenerating, setIsGenerating] = useState<boolean>(false)

  const handleFormat = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setProductType(event.target.value as ProductType)
  }

  return (
    <>
      <Stack spacing={2}>
        <Stack>
          <Typography variant="h3">Download SKU Labels</Typography>
          <Typography variant="subtitle1">
            Download SKU labels for vinyl records (ON.REC.XXX) or CDs
            (ON.CD.XXX) in CSV format for printing.
          </Typography>
        </Stack>
        <Box
          component="form"
          onSubmit={async (event) => {
            setIsGenerating(true)
            event.preventDefault()

            const numQty = Number(qty)
            let nextSku
            let constructSku
            try {
              if (productType === ProductType.VINYL) {
                nextSku = await get_and_increment_next_records_sku(numQty)
                constructSku = constructRecordsSku
              } else if (productType === ProductType.CD) {
                nextSku = await get_and_increment_next_cds_sku(numQty)
                constructSku = constructCdsSku
              } else {
                throw new Error(`Unexpected format: ${productType}`)
              }
            } catch (e: any) {
              if (e instanceof UnauthorisedError) {
                return user.setLoggedOut()
              }
              setIsGenerating(false)
              return
            }
            const data = []
            for (let i = 0; i < numQty; i++) {
              data.push({
                sku: constructSku(nextSku),
              })
              nextSku++
            }
            const csvExporter = new ExportToCsv({
              filename: 'thryft_sku_labels',
              useKeysAsHeaders: true,
            })
            csvExporter.generateCsv(data)
            setIsGenerating(false)
          }}
        >
          <Stack spacing={3} alignItems="flex-start">
            <TextField
              required
              select
              value={productType}
              label="Type"
              onChange={handleFormat}
            >
              {Object.values(ProductType).map((format) => (
                <MenuItem key={format} value={format}>
                  {format}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              required
              type="number"
              inputProps={{ min: 0 }}
              label={`No. of SKU labels`}
              value={qty}
              onChange={(event) => setQty(event.target.value)}
            />

            <ButtonWithLoading
              type="submit"
              variant="contained"
              disabled={qty === '0'}
              isLoading={isGenerating}
            >
              Download Labels
            </ButtonWithLoading>
          </Stack>
        </Box>
      </Stack>
    </>
  )
}

export default SkuLabels
